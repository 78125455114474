<script setup>
import {
  onMounted,
  ref,
  useCssModule,
  useSlots,
  useTemplateRef,
} from 'vue';
import { watchOnce } from '@vueuse/core';
import { scrollActiveElementIntoView } from '@@/utils/Components/ComponentUtils';

const props = defineProps({
  bottomBorder: {
    type: Boolean,
    default: false,
  },
  listClassNames: {
    type: String,
    default: '',
  },
  selectedTab: {
    type: Number,
    default: 0,
  },
  shouldScrollActiveElementIntoView: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();
const $style = useCssModule();

const tabs = ref([]);
const list = useTemplateRef('list');
const listItems = useTemplateRef('listItems');

const emit = defineEmits(['click']);

const listClass = [
  'tw-col-start-1',
  'tw-row-start-1',
  'tw-flex',
  'tw-mb-2.5',
  'tw-overflow-x-auto',
  'tw-overflow-y-hidden',
  props.bottomBorder ? 'tw-border-b border-color' : null,
  'hide-scrollbar-on-mobile',
  props.listClassNames,
];

// HACK: Consider just passing the tabs as a prop to the <Tabs> component so all this barely
// understood hackery can be removed!
onMounted(async () => {
  slots.default().forEach((vnode) => {
    if (vnode.type?.__name === 'Tab') {
      tabs.value.push(vnode);
    }
  });

  if (tabs.value.length === 0 && slots.default()?.[0]?.children) {
    slots.default()[0].children.forEach((vnode) => {
      if (vnode.type?.__name === 'Tab') {
        tabs.value.push(vnode);
      }
    });
  }

  if (props.shouldScrollActiveElementIntoView) {
    const doScroll = () => {
      const activeElement = listItems.value[props.selectedTab];
      scrollActiveElementIntoView({
        parent: list.value,
        activeElement,
        options: { block: 'end', inline: 'center' },
      });
    };

    if (!listItems.value) {
      watchOnce(listItems, doScroll);
    }
    else {
      doScroll();
    }
  }
});

const getTabComponent = (tab) => {
  if (tab.props?.href) {
    return 'a';
  }

  if (tab.props?.to) {
    return resolveComponent('NuxtLink');
  }

  return 'span';
};

const getTabProps = (tab) => {
  if (tab.props?.href) {
    return {
      class: 'tw-p-1.5 tw-no-underline',
      href: tab.props.href,
    };
  }

  if (tab.props?.to) {
    return {
      class: 'tw-p-1.5 tw-no-underline',
      to: tab.props.to,
    };
  }

  return { class: 'tw-p-1.5' };
};

const handleTabClick = ($event, index) => {
  if (index !== props.selectedTab) {
    emit('click', index);
  }
};

const tabClass = (index) => {
  const classNames = tabs?.value?.[index]?.props?.['class-names'];

  return [
    'tw-flex',
    'tw-mr-2 tw-px-1 lg:tw-px-2',
    'tw-cursor-pointer',
    'tw-transition',
    'tw-whitespace-nowrap tw-font-normal text-regular-color',
    $style.tabBorderBottom,
    index === props.selectedTab ? $style.selectedTab : undefined,
    classNames || $style.tab,
  ];
};
</script>

<template>
  <div :class="$style.container">
    <ul
      ref="list"
      :class="listClass"
    >
      <li
        v-for="(tab, index) in tabs"
        :key="tab.props.title"
        ref="listItems"
        :class="tabClass(index)"
      >
        <Component
          :is="getTabComponent(tab)"
          v-bind="getTabProps(tab)"
          @click="handleTabClick($event, index)"
        >
          {{ tab.props.title }}
        </Component>
      </li>
    </ul>
    <slot />
  </div>
</template>

<style module>
.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
}

.tabBorderBottom {
  border-bottom: 3px solid transparent;
}

.selectedTab {
  border-color: var(--brand-border-color);
  color: var(--brand-border-color);
}

@media (hover: hover) and (pointer: fine) {
  .tab:hover {
    border-color: var(--brand-border-color);
    color: var(--brand-border-color);
  }
}
</style>
